@import '../../../../stylesheets/utils/mixins.postcss';
@import '../../../../stylesheets/variables.postcss';

$heightOffset: 108px;

@keyframes pulsate {
  0% {opacity: 0.25;}
  50% {opacity: 1.0;}
  100% {opacity: 0.25;}
}

:global(.MapWithList-map-inner) {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(0);
  transition: transform 450ms ease-in-out-quart;
  width: 100%;

  @mixin above $map-breakpoint {
    transform: translateX(100%);
  }
}

.GeolocateButton {
  display: none;
  border: none;
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  text-align: right;
  border-radius: 32px;
  z-index: 40;
  background: white;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  height: 32px;
  width: auto;
  padding: 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  transition: background $hover-speed, color $hover-speed;
  padding-left: 34px;
  padding-right: 11px;

  span {
    display: inline-block;
    font-size: 0.85rem;
    vertical-align: middle;
    text-align: right;

    @mixin above $map-breakpoint {
      font-size: 0.75rem;
    }
  }

  svg {
    display: inline-block;
    position: absolute;
    top:0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;

    .GeolocateButton_icon {
      transition: fill $hover-speed;
    }
    .GeolocateButton_background {
      transition: fill $hover-speed;
    }
  }

  &:active, &:focus {
    outline: none;
  }

  &:active {
    .GeolocateButton_background {
      fill: white;
    }

    .GeolocateButton_icon {
      fill: $blue;
    }
  }

  &.GeolocateButton__locating {
    .GeolocateButton_icon {
      fill: $blue;
      animation: pulsate 1s ease-out;
      animation-iteration-count: infinite;
    }
  }

  @mixin above $map-breakpoint {
    display: block;
    top: auto;
    left: 0.5rem;
    bottom: 2rem;

    &:hover {
      background: $blue;
      color: white;
      .GeolocateButton_background {
        fill: $blue;
      }

      .GeolocateButton_icon {
        fill: white;
      }
    }
  }
}

.MapWithList_map_cta {
  align-items: center;
  background-color: white;
  box-shadow: 4px 4px 12px rgba(69, 69, 69, 0.41);
  border-radius: 6px;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 1rem 0.75rem;
  position: absolute;
  transform: translateX(-50%);
  width: calc(100% - 1.5rem);
  z-index: 199;

  &__sticky {
    left: 0.75rem;
    position: sticky;
    transform: none;
    width: calc(100% - 1.5rem);

    @mixin above $large {
      display: none;
    }
  }

  &_dismiss {
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
    right: 6px;
    top: 6px;
  }

  p {
    line-height: 1;
  }

  p:not(:first-child) {
    font-size: 0.875rem;
  }

  :global(.Button) {
    justify-content: center;
    width: 100%;
  }

  @mixin above $map-breakpoint {
    padding: 1.5rem;
    width: calc(100% - 4rem);
  }
}