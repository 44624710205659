@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.ImageViewer {}

.ImageViewer_slide {
  position: relative;
  height: 200px;

  @mixin above $small {
    height: 300px;
  }

  @mixin above $medium {
    height: 460px;
  }
}

.ImageViewer_slide__multiple {
  height: unset;
  padding: 0 0.5rem;

  .ImageViewer_image {
    position: static;
    padding-bottom: 100%;
  }
}

.ImageViewer_image {
  composes: Hero_image from "./content/Hero.module.postcss";
}

.ImageViewer :global(.slick-dots) {
  list-style-type: none;
  margin-top: 1rem;

  li:only-child {
    display: none;
  }

  li {
    margin-left: 0;
    display: inline-block;
    margin-right: 0.5rem;

    button {
      background: $yellow;
      border: none;
      border-radius: 50%;
      text-indent: -9999px;
      padding: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:active, &:focus {
        outline: none;
      }
    }

    &:global(.slick-active) {
      button {
        background: $blue;
      }
    }

  }
}
